import { Button, Col, Layout, List, Row, Typography } from "antd";
import MainFooter from "components/layout/main/Footer";
import MainHeader from "components/layout/main/Header";
import { Component } from "react";

const { Title } = Typography;
const { Content } = Layout;
export default class Pricing extends Component {
  render() {
    return (
      <>
        <Layout className="layout-default layout-signin">
          <MainHeader />
          <Content className="signin" style={{ marginBottom: "50px" }}>
            <Row gutter={[24, 0]} justify="center">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                style={{ textAlign: "center" }}
              >
                <Title className="mb-15">Pricing</Title>
                <Title
                  className="font-regular text-muted"
                  style={{ marginTop: "20px", marginBottom: "50px" }}
                  level={5}
                >
                  $5 USD per month. That's it! No hidden fees. It is 1/60th the
                  monthly price of your Starbucks coffee.
                </Title>
                <Button
                  type="primary"
                  style={{ width: "100px" }}
                  onClick={() =>
                    (window.location.href =
                      "https://buy.stripe.com/28og2gf93cBW2Q04gg")
                  }
                >
                  Buy Now
                </Button>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "10px" }}
              gutter={[24, 0]}
              justify="space-around"
            >
              {" "}
              <Col xs={{ span: 24 }} lg={{ span: 12 }} md={{ span: 16 }}>
                <Title className="font-regular" level={3}>
                  Instructions
                </Title>
                <List
                  className="font-regular"
                  header={
                    <>
                      <Title className="font-regular text-muted" level={5}>
                        1. We send these posts to all your messaging apps in
                        real-time through webhooks. Step by step instructions
                        are below for each messaging app:
                      </Title>
                      <Title
                        className="font-regular text-muted"
                        style={{ marginTop: "20px", marginBottom: "50px" }}
                        level={5}
                      >
                        2. Please send payment subscription to{" "}
                        <a
                          href="https://buy.stripe.com/28og2gf93cBW2Q04gg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Trending Today! News Subscription
                        </a>{" "}
                        to activate subscription service. Please provide the
                        webhook link there from step 1 so the service can be
                        activated. The payment portal is ran by egventures.net,
                        which is the parent company of Trending Today! FOR
                        TELEGRAM ONLY: provide your telegram username instead of
                        the webhook link in the payment portal.
                      </Title>
                    </>
                  }
                  dataSource={[
                    {
                      name: "Slack",
                      link: "https://app.tango.us/app/embed/0606db52-a368-4f81-990f-9c5efb974b4c?iframe=true",
                      video_title:
                        "Steps to Subscribe to Trending Today! with Slack",
                    },
                    {
                      name: "Discord",
                      link: "https://app.tango.us/app/embed/8eab07fd-5aeb-4cec-9862-95810928bd55?iframe=true",
                      video_title:
                        "Steps to Subscribe to Trending Today! with Discord",
                    },
                    {
                      name: "Google Spaces",
                      link: "https://app.tango.us/app/embed/7a96cfa3-9a02-4be4-a8ae-c7e66e5fd396?iframe=true",
                      video_title:
                        "Steps to Subscribe to Trending Today! with Google Spaces",
                    },
                  ]}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        color: "#000",
                        borderBottom: "none",
                        padding: "0px",
                        height: "800px",
                        width: "100%",
                      }}
                    >
                      <iframe
                        src={item.link}
                        sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                        security="restricted"
                        title={item.video_title}
                        width="100%"
                        height="100%"
                        referrerpolicy="strict-origin-when-cross-origin"
                        frameborder="0"
                        webkitallowfullscreen="webkitallowfullscreen"
                        mozallowfullscreen="mozallowfullscreen"
                        allowfullscreen="allowfullscreen"
                      ></iframe>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Content>
          <MainFooter />
        </Layout>
      </>
    );
  }
}
