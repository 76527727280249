/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import Dashboard from "pages/Dashboard";
// import { Redirect } from "react-router-dom";

import { Route, Switch } from "react-router-dom";
import "./assets/styles/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
// import "antd/dist/reset.css";s
// import "./assets/themes/antd.dark.css";
// import Dash from "./components/layout/dash/Dash";
import About from "./pages/About";
// import Billing from "./pages/Billing";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
// import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
// import Tables from "./pages/Tables";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/about" exact component={About} />
        <Route path="/pricing" exact component={Pricing} />
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        {/* <Dash>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/profile" component={Profile} />
          <Redirect from="*" to="/dashboard" />
        </Dash> */}
      </Switch>
    </div>
  );
}

export default App;
