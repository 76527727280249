/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Button, Col, Divider, Layout, List, Row, Typography } from "antd";
import MainFooter from "components/layout/main/Footer";
import MainHeader from "components/layout/main/Header";
import { Component } from "react";

const { Title, Text } = Typography;
const { Content } = Layout;

export default class Home extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "//telegram.im/widget-button/index.php?id=@trending_today_app";
    script.async = true;
    document.head.appendChild(script);
  }

  render() {
    return (
      <>
        <Layout className="layout-default layout-signin">
          <MainHeader />
          <Content className="signin">
            <Row
              gutter={[24, 0]}
              justify="center"
              style={{ marginTop: "20px", marginBottom: "50px" }}
            >
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                style={{ textAlign: "center", alignItems: "center" }}
              >
                <Title className="mb-15">Trending Today!</Title>
                <Text
                  className="font-regular text-muted ant-typography-h3"
                  style={{
                    color: "black",
                    marginBottom: "30px",
                    whiteSpace: "pre-line",
                  }}
                >
                  Stay informed with real-time notification service of trending
                  posts from multiple tech sources to your messaging apps
                </Text>
                <Divider style={{ opacity: "0" }} />
                <Button
                  type="primary"
                  style={{ width: "100px" }}
                  onClick={() => (window.location.href = "/pricing")}
                >
                  Get Started
                </Button>
                <Divider style={{ opacity: "0" }} />
                <a
                  href="https://telegram.me/trending_today_app"
                  target="_blank"
                  className="telegramim_button telegramim_shadow telegramim_pulse"
                  style={{
                    display: "inline-block",
                    width: "200px",
                    background: "#27A5E7",
                    boxShadow: "1px 1px 5px #27A5E7",
                    color: "#FFFFFF",
                    borderRadius: "50px",
                  }}
                  title="Trending Today Demo Telegram"
                  rel="noreferrer"
                >
                  <i></i> Trending Today!{" "}
                  <small>
                    <span
                      className="telegramim_count"
                      data-for="@trending_today_app"
                    >
                      ...
                    </span>{" "}
                    participants
                  </small>
                </a>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "20px", marginBottom: "50px" }}
              gutter={[24, 0]}
              justify="space-around"
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <Text
                  className="ant-typography-h3 font-regular text-muted"
                  style={{
                    color: "black",
                    marginBottom: "20px",
                    whiteSpace: "pre-line",
                  }}
                >
                  Get top trending tech posts from:
                </Text>
                <List
                  className="font-regular"
                  dataSource={[
                    "GitHub",
                    "Product Hunt",
                    "Hacker News",
                    "Designer News",
                    "Dev.to",
                    "Lobste.rs",
                    "TabNews BR",
                    "Hackernoon",
                    "Google Trends",
                  ]}
                  renderItem={(item, index) => (
                    <List.Item
                      style={{
                        color: "#000",
                        borderBottom: "none",
                        padding: "0px",
                      }}
                    >
                      {index + 1}. {item}
                    </List.Item>
                  )}
                />
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <Text
                  className="ant-typography-h3 font-regular text-muted"
                  style={{
                    color: "black",
                    marginBottom: "20px",
                    whiteSpace: "pre-line",
                  }}
                >
                  Into these messaging apps:
                </Text>
                <List
                  className="font-regular"
                  dataSource={["Discord", "Slack", "Google Chat", "Telegram"]}
                  renderItem={(item, index) => (
                    <List.Item
                      style={{
                        color: "#000",
                        borderBottom: "none",
                        padding: "0px",
                      }}
                    >
                      {index + 1}. {item}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Content>
          <MainFooter />
        </Layout>
      </>
    );
  }
}
