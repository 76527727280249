import { Card, Col, Collapse, Divider, Layout, Row, Typography } from "antd";
import MainFooter from "components/layout/main/Footer";
import MainHeader from "components/layout/main/Header";
import { Component } from "react";

const { Content } = Layout;
const { Title, Text, Link } = Typography;
const { Panel } = Collapse;

const faqData = [
  [
    "What is the product?",
    "Trending Today! is a real-time notification service of trending posts from multiple tech sources to your messaging apps.",
  ],
  [
    "What are the brand's values?",
    "We want to keep users informed with the latest tech news as they come in with links to the news sources.",
  ],
  [
    "What does the brand want the customer to know?",
    "We want the customer to know that we are a real-time notification service of trending tech posts from multiple sources to your messaging apps.",
  ],
  [
    "Who is your customer avatar?",
    "Our customer avatar is a person with a messaging app that wants to be informed with the latest tech news.",
  ],
  [
    "What problem do they have?",
    "They have the problem of not being able to stay informed with the latest news quickly.",
  ],
  [
    "What do most of these customers say about this problem?",
    "They say that they want to stay informed with the latest news but do not have a centralized location to do so with links to the news sources.",
  ],
  [
    "What do most of these customers say about this product?",
    "This product helps keep them up to date with the latest and greatest tech news.",
  ],
  [
    "How are these customers getting this message?",
    "We have a website that explains the product and a blog that explains the product.",
  ],
  [
    "What actions do you want them to take?",
    "We want them to follow the instructions above and get started quickly.",
  ],
  [
    "How do they like to be spoken to?",
    "They like to be spoken to in a friendly and professional manner.",
  ],
  ["What is the name of business?", "Trending Today!"],
  [
    "What is your business in one sentence?",
    "Trending Today! is a real-time notification service of trending posts from multiple tech sources to your messaging apps.",
  ],
  [
    "What problem is your business solving?",
    "We want to keep users informed with the latest tech news as they come in with links to the news sources.",
  ],
  [
    "What is your specific solution to the problem?",
    "We want the customer to know that we are a real-time notification service of trending tech posts from multiple sources to your messaging apps.",
  ],
  ["How do you make money?", "We make money by charging a subscription fee."],
  ["What will revenue be this year?", "We expect to make $100,000 this year."],
  [
    "What is the big vision?",
    "We want to be the go-to place for tech news across multiple messaging servers.",
  ],
  [
    "Why are you the right person to solve this problem?",
    "I am the right person to solve this problem because I have the technical knowhow and can get your trending posts to market quickly.",
  ],
  [
    "How did you come up with this idea?",
    "I came up with this idea because I wanted to stay informed with the latest tech news as they come in with links to the news sources.",
  ],
  [
    "What's your scrapiest story?",
    "My scrapiest story is when I was scraping the web for the latest tech news and I came across a site that which allowed users to change the titles of their posts and thus created repeated links. I had to find a way to remove the repeated links.",
  ],
];

export default class About extends Component {
  render() {
    return (
      <>
        <Layout className="layout-default layout-signin">
          <MainHeader />
          <Content
            className="signin"
            style={{ marginTop: "20px", marginBottom: "50px" }}
          >
            <Row gutter={[24, 0]} justify="center">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                style={{ textAlign: "center" }}
              >
                <Title className="mb-15">About Us</Title>
                <Divider />
                <Text style={{ fontSize: "20px" }}>Trending Today is an </Text>
                <Link
                  style={{ fontSize: "20px" }}
                  href="https://egventures.net"
                  target="_blank"
                >
                  EG Ventures
                </Link>
                <Text style={{ fontSize: "20px" }}> company!</Text>
              </Col>
            </Row>
            <Divider />
            <Row gutter={[24, 0]} justify="center">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                style={{ textAlign: "center" }}
              >
                <Title level={2}>FAQ</Title>
                <Card style={{ margin: "0 auto", maxWidth: "800px" }}>
                  <Collapse accordion>
                    {faqData.map((item, index) => (
                      <Panel header={item[0]} key={index}>
                        <p style={{ whiteSpace: "normal" }}>{item[1]}</p>
                      </Panel>
                    ))}
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </Content>
          <MainFooter />
        </Layout>
      </>
    );
  }
}
