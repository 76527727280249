import {
  BankOutlined,
  ContactsOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { logo } from "assets/images/svg";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
const { Header } = Layout;

function MainHeader() {
  const StyledLink = styled(RouterLink)`
    color: black;
    text-decoration: none;
    role: menuitem;

    &:hover {
      color: blue;
    }
  `;

  return (
    <Header>
      <Menu
        style={{ width: "100%", justifyContent: "center" }}
        mode="horizontal"
        expandIcon={<EllipsisOutlined />}
      >
        <Menu.Item key="1">
          <StyledLink to="/">{logo} Trending Today!</StyledLink>
        </Menu.Item>
        <Menu.Item key="2">
          <StyledLink to="/about">{<InfoCircleOutlined />} About</StyledLink>
        </Menu.Item>
        <Menu.Item key="3">
          <StyledLink to="/pricing">{<BankOutlined />} Pricing</StyledLink>
        </Menu.Item>
        <Menu.Item key="4">
          <StyledLink to="/contact">{<ContactsOutlined />} Contact</StyledLink>
        </Menu.Item>
        <Menu.Item key="5">
          <StyledLink to="/pricing">
            {<UpCircleOutlined />} Get Started
          </StyledLink>
        </Menu.Item>
      </Menu>
    </Header>
  );
}

export default MainHeader;
