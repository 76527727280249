import { Button, Col, Form, Input, Row } from "antd";
import axios from "axios";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function ContactUsForm() {
  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    email: "",
    message: "",
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      setInputs({
        name: "",
        email: "",
        message: "",
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (inputs.name.trim() === "") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        name: "Please enter your name.",
      }));
      isValid = false;
    } else {
      setFieldErrors((prevErrors) => ({ ...prevErrors, name: "" }));
    }

    if (
      inputs.email.trim() === "" ||
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(inputs.email)
    ) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address.",
      }));
      isValid = false;
    } else {
      setFieldErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }

    if (inputs.message.trim() === "") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        message: "Please enter a message.",
      }));
      isValid = false;
    } else {
      setFieldErrors((prevErrors) => ({ ...prevErrors, message: "" }));
    }

    return isValid;
  };

  const handleOnSubmit = async (e) => {
    if (!validateForm()) {
      return;
    }
    setServerState({ submitting: true });

    if (executeRecaptcha) {
      // Get reCAPTCHA token
      const token = await executeRecaptcha("submit");

      // Add reCAPTCHA token to the request data
      const dataWithRecaptcha = {
        ...inputs,
        "g-recaptcha-response": token,
      };

      axios({
        method: "POST",
        url: "https://formspree.io/f/xayzjzjg",
        data: dataWithRecaptcha,
      })
        .then((r) => {
          handleServerResponse(true, "Thanks!");
        })
        .catch((r) => {
          handleServerResponse(false, r.response.data.error);
        });
    } else {
      handleServerResponse(false, "reCAPTCHA not available. Please try again.");
    }
  };

  return (
    <div className="section">
      <Row justify="center">
        <Col xs={24} sm={24} md={16}>
          <h2 className="title">Contact us</h2>
          <h4 className="description">
            Provide details of what you want to achieve and we will get back to
            you in 24 hours.
          </h4>
          <Form
            onFinish={handleOnSubmit}
            className="row-col"
            style={{ marginTop: "50px", color: "#000" }}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  required
                  help={fieldErrors.name}
                  validateStatus={fieldErrors.name ? "error" : ""}
                >
                  <Input
                    id="name"
                    placeholder="Your Name"
                    name="name"
                    onChange={handleOnChange}
                    value={inputs.name}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  required
                  help={fieldErrors.email}
                  validateStatus={fieldErrors.email ? "error" : ""}
                >
                  <Input
                    id="email"
                    placeholder="Your Email"
                    name="email"
                    type="email"
                    onChange={handleOnChange}
                    value={inputs.email}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  required
                  help={fieldErrors.message}
                  validateStatus={fieldErrors.message ? "error" : ""}
                >
                  <Input.TextArea
                    id="message"
                    name="message"
                    placeholder="Your Message"
                    rows={5}
                    onChange={handleOnChange}
                    value={inputs.message}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={1}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={serverState.submitting}
                >
                  Send Message
                </Button>
                {serverState.status && (
                  <p className={!serverState.status.ok ? "errorMsg" : ""}>
                    {serverState.status.msg}
                  </p>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
