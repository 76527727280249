/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ColorModeContextProvider } from "providers/ColorModeProvider";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LdWtnAlAAAAAK4fr3oZ5BAk8e16UrdwY57haXe5">
    <ColorModeContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ColorModeContextProvider>
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);
